import React from 'react';
import Image from 'next/image';
import Modal from 'components/Modal/_default/Modal';
import digitalLearning from 'assets/digital-learning.svg';
import calendarBlank from 'assets/CalendarBlank.svg';
import clock from 'assets/Clock.svg';
import mapPin from 'assets/MapPin.svg';
import externalLinkIcon from 'assets/external-link-white.svg';
import rnaAnalytics from 'services/analytics/rnaAnalytics';
import { AnalyticsEvent, AnalyticsEventAction, AnalyticsPageSectionName } from 'constants/analytics';

interface CloutTrainingProps {
    isVisible: boolean; 
    toggle: (value: boolean) => void;
}

const CloutTraining: React.FC<CloutTrainingProps> = ({ isVisible, toggle }) => {
    const handleRegisterBtnClick = () => {
        rnaAnalytics.clickEvent({
            action: AnalyticsEventAction.TrainingRequested,
            eventData: {
                [AnalyticsEvent.Extras]: {
                    [AnalyticsEvent.PageSectionName]: AnalyticsPageSectionName.CloutTraining
                }
            }
        }).send();
    }

    return (
        <>
            <Modal
                isVisible={isVisible}
                onClose={() => toggle(!isVisible)}
                alignRight
            >
                <section className='w-[420px] h-full flex flex-col justify-between overflow-y-scroll no-scrollbar'>
                    <div className='flex flex-col justify-between'>
                        <p className={'text-theme_1_black font-bold text-2xl mb-2'}>Clout Training</p>
                        <p className='text-sm text-[#424242] mb-2'>
                            Learn how to link your Shopify store to Roposo Clout and use all the awesome 
                            features Clout provides to help you take your dropshipping business to the next level.
                        </p>
                        <div className='mx-auto mb-8'>
                            <Image 
                                src={digitalLearning} 
                                alt="Clout Training" 
                            />
                        </div>
                        <div className='flex flex-col gap-[19px] justify-between font-normal text-[#424242]'>
                            <div className='flex gap-[20px] items-center'>
                                <div className='self-start'>
                                    <Image
                                        src={calendarBlank}
                                        alt='Clout Training Event Calendar'
                                    />
                                </div>
                                <div className='flex flex-col gap-[5px] grow'>
                                    <p className='text-sm leading-[10px]'>Day</p>
                                    <p className='flex justify-between'>
                                        <span className='text-xl'>
                                            {'Every Tuesday & Friday'}
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <hr className='border-theme_1_border' />
                            <div className='flex gap-[20px] items-center'>
                                <div className='self-start'>
                                    <Image
                                        src={clock}
                                        alt='Time'
                                    />
                                </div>
                                <div className='flex flex-col gap-[5px] grow'>
                                    <p className='text-sm leading-[10px]'>Time</p>
                                    <p className='flex justify-between'>
                                        <span className='text-xl'>
                                            3 PM - 4 PM
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <hr className='border-theme_1_border' />
                            <div className='flex gap-[20px] items-center'>
                                <div className='self-start'>
                                    <Image
                                        src={mapPin}
                                        alt='Location'
                                    />
                                </div>
                                <div className='flex flex-col gap-[5px] grow'>
                                    <p className='text-sm leading-[10px]'>Location</p>
                                    <p className='flex justify-between'>
                                        <span className='text-xl'>
                                            Zoom Meeting
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'sticky bottom-0 pt-[30px] bg-white'}>
                        <a
                            href="https://docs.google.com/forms/d/e/1FAIpQLSflz7Ah7ig5g8G3tslwbv7Up1ffYO0L_I1Tc6xmk6b7H7ar5w/viewform?usp=preview"
                            target="_blank"
                            className={'flex justify-center gap-[10px] items-center w-full bg-theme_1_black text-theme_1_white p-2 text-[14px] font-semibold rounded-[2px] disabled:opacity-70'}
                            rel="noreferrer"
                            onClick={handleRegisterBtnClick}
                        >
                            Register For Free <Image src={externalLinkIcon}/>
                        </a>
                    </div>
                </section>
          </Modal>
        </>
    );
};

export default CloutTraining;
